<template>
  <div class="rentQi">
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 11 }"
    >
      <a-form-model-item label="生成数量" prop="Number">
        <a-input v-model="form.Number" allowClear />
      </a-form-model-item>
      <a-form-model-item label="活动名称">
        <a-input v-model="form.TagName" allowClear />
      </a-form-model-item>
      <a-form-model-item label="活动标记">
        <a-input v-model="form.Tag" allowClear />
      </a-form-model-item>

      <a-form-model-item label="课时数">
        <a-input v-model="form.LessonNum" allowClear />
      </a-form-model-item>
      <a-form-model-item label="状态">
        <a-select placeholder="请选择" v-model="form.Status" allowClear>
          <a-select-option value="">全部</a-select-option>
          <a-select-option :value="1">已兑换</a-select-option>
          <a-select-option :value="0">未兑换</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="课时类型">
        <a-select placeholder="请选择" v-model="form.ExchangeType" allowClear>
          <a-select-option value="">全部</a-select-option>
          <a-select-option :value="1">课时</a-select-option>
          <a-select-option :value="3">课包</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="平台类型">
        <a-select placeholder="请选择" v-model="form.Type" allowClear>
          <a-select-option value="">全部</a-select-option>
          <a-select-option :value="1">七田真</a-select-option>
          <a-select-option :value="2">天猫</a-select-option>
          <a-select-option :value="3">京东</a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="最后兑换日期">
        <a-date-picker
          style="width: 150px"
          v-model="form.LastDate"
          format="YYYY-MM-DD"
          valueFormat="YYYY-MM-DD"
          placeholder="最后兑换日期"
          @change="_searchDate"
        />
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
export default {
  name: "rentQi",

  data() {
    return {
      form: {
        TagName: "",
        Status: "",
        Tag: "",
        ExchangeType: "",
        Type: "",
        LastDate: "",
        Number: "",
        LessonNum: 0,
      },
      rules: {
        Number: [
          {
            required: true,
            message: "请输输入生成数量",
            trigger: "blur",
          },
        ],
      },

      userinfo: JSON.parse(sessionStorage.getItem("userInfo")),
      departmentList: [],
    };
  },
  props: {
    formData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  created() {
    // 如果是编辑数据，修正表单数据类型
    if (this.formData.Id) {
      for (const key in this.form) {
        if (typeof this.form[key] === "boolean") {
          this.form[key] = !!this.formData[key];
        } else if (key === "SchoolId" || key === "ApplyTo") {
          this.form[key] = this.formData[key]
            ? this.formData[key].split(",")
            : [];
        } else {
          this.form[key] = this.formData[key];
        }
      }
    }
    console.log(this.form);
  },
  methods: {
    _searchDate() {
      //  this.form.LastDate = this.__moment__(e).format('YYYY/MM/DD');
    },
    handleChange(key) {
      if (this.form[key].some((item) => item === "all")) {
        this.form[key] = ["all"];
        this.$refs[key].blur();
      }
    },

    // 日期控件值变化
    dateChange(val) {
      if (val[0]) {
        this.form.StartTime = val[0];
        this.form.EndTime = val[1];
      } else {
        this.form.StartTime = "";
        this.form.EndTime = "";
      }
    },
    handleChange(key, val) {
      if (this.form[key].some((item) => item === "all")) {
        this.form[key] = ["all"];
        this.$refs[key].blur();
      }
    },
    onSubmit() {
      return new Promise((resolve, reject) => {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            resolve(this.form);
          } else {
            reject(false);
          }
        });
      });
    },
  },
};
</script>

<style scoped lang="less">
.rentQi {
  background: #ffffff;
  margin-top: 15px;
  min-height: 800px;
  padding: 15px;
}
</style>
